import { html } from 'lit';
import { FilCard } from '@fil-global/fil-card/src/index.js';
import customStyles from './fil-card.styles';

export class FilCardExtended extends FilCard {
  static get properties() {
    return {
      variation: { type: String }, //special card type:  'icon-card' | 'selector-card'
      bgColor: { type: String }, // blue-500, blue-400, blue-300, teal-500 (only for selector card)
      ...super.properties
    };
  }

  static get styles() {
    return [...super.styles, customStyles];
  }

  constructor() {
    super();
    this.variation = '';
    this.bgColor = 'blue-500';
  }

  renderIconImage() {
    return html`
      <div
        class="fil-card__icon"
        style="background-image:url('${this.image}')"
        @click=${this.onClick}
        tabindex="-1"
      >
        <div class="fil-card__image-label"><slot name="label"></slot></div>
      </div>
    `;
  }

  renderSelectorImage() {
    if (this.image) {
      return html`
        <div
          class="fil-card__icon fil-card__selector ${this.bgColor}"
          style="background-image:url('${this.image}')"
          @click=${this.onClick}
          tabindex="-1"
        >
          <div class="fil-card__image-label"><slot name="label"></slot></div>
        </div>
      `;
    } else {
      return html`
        <div class="fil-card__selector ${this.bgColor}">
          <fil-icon class="fil-card__icon" icon="${this.icon}"></fil-icon>
        </div>
      `;
    }
  }

  renderImage() {
    if (this.image && this.variation == 'icon-card') {
      return this.renderIconImage();
    } else if (this.variation == 'selector-card') {
      return this.renderSelectorImage();
    }

    return super.renderImage();
  }
}
