import { modalButtonEvent } from './utils';
import { registerLazyLoadAttribute } from '_fil/fil-global-frontend/lazy-load';
import $ from 'jquery';

$(() => {
  const $footers = $('.fil-cards fil-card-footer');

  $footers.each((i, el) => {
    //FIX: footer button not clickable issue.
    $(el.shadowRoot)
      .find('.fil-card__footer')
      .css('position', 'relative');
  });

  $footers.on('click', 'fil-button, .fil-button', function() {
    const modal_id = $(this).attr('data-open');
    modalButtonEvent(modal_id);
  });
});

// lazy load
registerLazyLoadAttribute('fil-card', ['icon', 'image']);
