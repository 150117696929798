import { css } from 'lit';

export default css`
  .fil-text-media-block__section:first-child {
    background: unset;
  }

  .fil-text-media-block__video-image,
  .fil-text-media-block__icon-container {
    display: none;
  }
`;
