import { html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { FilTextMediaBlock } from '@fil-global/fil-text-media-block/src/index.js';
import customStyles from './fil-text-media-block.styles';

export class FilTextMediaBlockExtended extends FilTextMediaBlock {
  static get properties() {
    return {
      extraCtaExtraProps: { type: Object },
      extraCtaKind: { type: String }, // "primary" | "secondary" | "tertiary" | "textlink"
      extraCtaText: { type: String },
      extraCtaUrl: { type: String },
      extraCtaIcon: { type: String },
      extraCtaIconSize: { type: Number },
      dataOpen: { type: String },
      extraDataOpen: { type: String },
      ...super.properties
    };
  }

  static get styles() {
    return [...super.styles, customStyles];
  }

  ctaTemplate() {
    let ctaHtml = '';
    let extraCtaHtml = '';
    if (this.ctaKind) {
      ctaHtml = html`
        <fil-button
          kind="${this.ctaKind}"
          label="${this.ctaText}"
          url="${ifDefined(this.ctaUrl)}"
          size="standard"
          icon="${this.ctaIcon}"
          iconPosition="right"
          iconSize="${this.ctaIconSize}"
          data-open="${this.dataOpen}"
          extraProps=${ifDefined(this.ctaExtraProps)
    ? JSON.stringify({ ...this.ctaExtraProps })
    : null}
        ></fil-button>
      `;

      if (this.extraCtaKind) {
        extraCtaHtml = html`
          <fil-button
            kind="${this.extraCtaKind}"
            label="${this.extraCtaText}"
            url="${ifDefined(this.extraCtaUrl)}"
            size="standard"
            icon="${this.extraCtaIcon}"
            iconPosition="right"
            iconSize="${this.extraCtaIconSize}"
            data-open="${this.extraDataOpen}"
            extraProps=${ifDefined(this.extraCtaExtraProps)
    ? JSON.stringify({ ...this.extraCtaExtraProps })
    : null}
          ></fil-button>
        `;
        ctaHtml = html`
          <fil-button-group stacked>
            ${ctaHtml}${extraCtaHtml}
          </fil-button-group>
        `;
      }

      return html`
        <p
          class="fil-text-media-block__cta ${this.ctaKind === 'textlink'
    ? 'fil-text-media-block__cta--link'
    : 'fil-text-media-block__cta'}"
        >
          ${ctaHtml}
        </p>
      `;
    }
  }
}
