import Cookies from 'js-cookie';
import { FILModal } from './modal';

export class FILComplianceModal extends FILModal {
  get complianceCookieName() {
    return 'FILCOMPLIANCECOOKIE';
  }

  checkEnv() {
    const complianceCheck = Cookies.get(this.complianceCookieName);
    if (complianceCheck !== 'true') {
      this.open();
    }
  }

  bindEvents() {
    this.$modal.find('.footer-button--primary').on('click touch', () => {
      this.setComplianceCookie();
      this.close();
    });
    this.$modal.find('.footer-button--secondary').on('click touch', () => {
      this.close();
    });

    const $modalPopupWrapper = this.$modal.find('.fil-modal-popup');
    const $contentWrapper = this.$modal.find('.fil-modal-content-wrapper');
    $modalPopupWrapper.find('.terms-of-conditions').on('click touch', evt => {
      evt.preventDefault();
      $modalPopupWrapper.hide();
      $contentWrapper.show();
    });
  }

  setComplianceCookie() {
    Cookies.set(this.complianceCookieName, 'true');
  }
}

export class FILEditorialComplianceModal extends FILComplianceModal {
  get complianceCookieName() {
    return 'EDITORIALCOMPLIANCECOOKIE';
  }
}
