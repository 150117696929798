import { css } from 'lit';

export default css`
  :host(fil-card) .fil-card__image:after {
    content: none;
  }

  :host(fil-card) .fil-card__selector {
    margin: 0;
    padding: 30px 0;
    color: #fff;
  }

  :host(fil-card) .fil-card__selector.blue-500 {
    background-color: var(--fil-color-base-blue-500);
  }

  :host(fil-card) .fil-card__selector.blue-400 {
    background-color: var(--fil-color-base-blue-400);
  }

  :host(fil-card) .fil-card__selector.blue-300 {
    background-color: var(--fil-color-base-blue-300);
  }

  :host(fil-card) .fil-card__selector.teal-500 {
    background-color: var(--fil-color-base-teal-500);
  }
`;
