import $ from 'jquery';

$(() => {
  $('.fil-messaging').each((_, elem) => {
    elem.addEventListener('close.gds.page-level-message', event => {
      let isShown = event.detail.value;
      if (!isShown) {
        elem.classList.add('hide');
      }
    });
  });
});
