import './article_detail_layout';
import { ArticlePaginator } from './article_paginator';
import $ from 'jquery';

$(() => {
  const multiple_paginations = $('[data-paginator="article"]').length > 1;
  $('[data-paginator="article"]').each((i, el) => {
    const paginator = new ArticlePaginator(el, {
      disableSEO: multiple_paginations,
      disableURL: multiple_paginations
    });
    paginator.rebuild();
  });
});
