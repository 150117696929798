import $ from 'jquery';
import { registerLazyLoadAttribute } from '_fil/fil-global-frontend/lazy-load';
// lazy load
registerLazyLoadAttribute('fil-text-media-block-g', ['imageurl']);

$(() => {
  $('fil-text-media-block-g')
    .map((i, e) => e.shadowRoot)
    .find('fil-button')
    .on('click', evt => {
      const modalID = $(evt.target).data('open');
      if (modalID) {
        $(`#${modalID} > fil-modal`).prop('active', true);
      }
    });
});
