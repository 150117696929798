import $ from 'jquery';

const queryString = require('qs');
const PAGE_PARAM = 'p';
const PER_PAGE_PARAM = 'c';
const DEFAULT_PAGE_FROM = 1;
const DEFAULT_PER_PAGE = 10;

/**
 * Paginator
 */
export class Paginator {
  constructor(rootElement, opts = {}) {
    // Optionally disable SEO and push state functionality
    this.disableSEO = opts.disableSEO || false;
    this.disableURL = opts.disableURL || false;

    this.$root = $(rootElement);

    // $results -- container for rendered result elements
    this.$results = this.$root.find('[data-paginator-results]');

    // $counter -- displays the total number of results
    this.$counter = this.$root.find('[data-paginator-counter]');

    // $navigation -- container for navigation button elements
    this.$navigation = this.$root.find('.paginator__nav fil-pagination');
    this.perPage = Number(this.$navigation.prop('itemsPerPage')) || DEFAULT_PER_PAGE;
    this.page = DEFAULT_PAGE_FROM;

    $(window).on('popstate', () => this.updateStateFromUrl());

    this.paginator_initiated = false;
    this.$navigation[0].addEventListener('change.gds.pagination', event => {
      const perPage = Number(event.detail.value.itemsPerPage) || DEFAULT_PER_PAGE;
      const page = Number(event.detail.value.active) || DEFAULT_PAGE_FROM;
      this.setState(page, perPage, !this.paginator_initiated);
      this.paginator_initiated = true;
    });
  }

  resetPage() {
    this.page = DEFAULT_PAGE_FROM;
    this.$navigation[0].currentPage = 1;
  }

  /**
   * Calculate the maximum number of pages required to display all of the the
   * current results.
   *
   * @return {number} Maximum number of pages
   */
  getMaxPages() {
    const total = this.getTotalResults();
    const maxPages = total === 0 ? 0 : Math.ceil(total / this.perPage);
    return maxPages;
  }

  /**
   * Return the results for a particular page.
   *
   * @abstract
   * @param {number} page Page number to retrieve results from
   * @return {array} Array of results in a format understood by buildResult()
   */
  // eslint-disable-next-line no-unused-vars
  getResultsForPage(page) {
    throw new ReferenceError('Must implement getResultsForPage() method');
  }

  /**
   * Retrieve the total number of results
   *
   * @abstract
   * @return {number} Total results
   */
  getTotalResults() {
    throw new ReferenceError('Must implement getTotalResults() method');
  }

  /**
   * Perform some acton when the page changes
   */
  onPageChange() {
    return;
  }

  /**
   * Perform some acton when the perpage changes
   */
  onPerPageChange() {
    return;
  }

  /**
   * Perform some acton when the state (i.e. page or perpage) changes.
   */
  onStateChange() {
    return;
  }

  /**
   * Set the page number.
   *
   * @param {number} page Page number to view
   */
  setPage(page) {
    this.setState(page, this.perPage);
  }

  /**
   * Set both the page and perPage attributes simultaneously. Perform a sanity
   * check on the new page value. Rebuild the view if either value has
   * changed.
   *
   * @param {number}  page    Page number to view
   * @param {number}  perPage Number of results to display per page
   * @param {boolean} silent  Do not trigger the state change event
   */
  setState(page, perPage, silent = false) {
    page = isNaN(page) ? DEFAULT_PAGE_FROM : page;
    perPage = isNaN(perPage) ? DEFAULT_PER_PAGE : perPage;

    const pageChanged = this.page !== page;
    const perPageChanged = this.perPage !== perPage;

    if (pageChanged || perPageChanged) {
      // This must be set first, as getMaxPages() uses it for calculation
      this.perPage = Math.max(perPage, 1);

      const min = 1;
      const max = this.getMaxPages();
      const newPage = Math.min(Math.max(page, min), max);
      this.page = newPage;

      this.rebuild();

      if (!silent) {
        this.onStateChange();

        if (pageChanged) {
          this.onPageChange();
        }

        if (perPageChanged) {
          this.onPerPageChange();
        }

        this.$root.trigger('paginator:results-height-changed');
      }
    }
  }

  /**
   * Build the HTML for a single result
   *
   * @abstract
   * @param {object} result Data for a single result
   * @return {HTML element} HTML element representing the result
   */
  // eslint-disable-next-line no-unused-vars
  buildResult(result) {
    throw new ReferenceError('Must implement buildResult() method');
  }

  /**
   * Rebuild both the navigation and the results
   */
  rebuild() {
    let results = this.getResultsForPage(this.page - 1); //in gds3 pagination we use 1-based index, but in js array, we use 0-based index.
    let total = this.getTotalResults();
    this.buildResults(results);
    this.buildTotalResultsOutput(total);
    this.updateURL();
    this.updateSEO();
  }

  /**
   * Generate the list of results to display on the current page
   *
   * @param {array} results Array of results in a format understood by
   * buildResult()
   */
  buildResults(results) {
    this.$results.empty();
    results.forEach(result => {
      this.buildResult(result).appendTo(this.$results);
    });
  }

  /**
   * Render the total number of results
   *
   * @param  {number} total Number of results to display
   */
  buildTotalResultsOutput(total) {
    const navigatorDOM = this.$navigation[0];
    navigatorDOM.items = total;
    this.$counter.text(total);
    this.$navigation.parent().toggleClass('hide', total < this.perPage);
  }

  /**
   * Update the pagination state based on the query string
   */
  updateStateFromUrl() {
    if (this.disableURL) {
      return;
    }

    const parsed = queryString.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    // Retrieve the per page value from the URL, or fall back to the value
    // of the dropdown selector
    const parsedPerPage = parsed[PER_PAGE_PARAM];
    const page = Number(parsed[PAGE_PARAM]);
    const itemsPerPage = Number(parsedPerPage);

    this.setState(page, itemsPerPage, true);
    if (page && itemsPerPage) {
      this.updateWebComponentStates(page, itemsPerPage);
    }
  }

  /**
   * update pagination webcomponent states.
   *
   */
  updateWebComponentStates(page, itemsPerPage) {
    const navigatorDOM = this.$navigation[0];
    navigatorDOM.itemsPerPage = itemsPerPage;
    navigatorDOM.pages = navigatorDOM.getPages();
    if (navigatorDOM.shadowRoot) {
      navigatorDOM.shadowRoot.querySelector('fil-select').value = `${itemsPerPage}`;
    }
  }

  /**
   * Update the link rel="next" and rel="prev" tags
   *
   * These tags link to the neighbouring pages and help improve SEO. If the
   * respective neighbour page does not exist, the <link> tag should be
   * removed completely.
   */
  updateSEO() {
    if (this.disableSEO) {
      return;
    }

    /**
     * Retrieve or generate a <link> tag
     *
     * @param  {string} type 'next' or 'prev'
     * @return {jQuery} jQuery object containing <link> tag
     */
    const getOrCreateLink = type => {
      let $link = $(`link[rel="${type}"]`);
      if (!$link.length) {
        $link = $(`<link rel="${type}">`).appendTo('head');
      }
      return $link;
    };

    /**
     * Generate the URL to link to a particular pagination page, retaining
     * the existing query string parameters.
     *
     * @param  {number} page
     * @return {string} SEO URL for specified page
     */
    const makeUrl = page => {
      const search = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true
      });

      search[PAGE_PARAM] = page;
      search[PER_PAGE_PARAM] = this.perPage;

      return `${window.location.pathname}?${queryString.stringify(search)}`;
    };

    /**
     * Update the <link> tag for the specified direction and page number
     *
     * @param  {string} type 'next' or 'prev'
     * @param  {number} page Page number
     */
    const addLink = (type, page) => {
      const url = makeUrl(page);
      const $link = getOrCreateLink(type);
      $link.prop('href', url);
    };

    /**
     * Remove <link> tag
     *
     * @param  {string} type 'next' or 'prev'
     */
    const removeLink = type => {
      $(`link[rel="${type}"]`).remove();
    };

    // rel=prev
    if (this.page === 0) {
      removeLink('prev');
    } else {
      addLink('prev', this.page - 1);
    }

    // rel=next
    if (this.page === this.getMaxPages() - 1) {
      removeLink('next');
    } else {
      addLink('next', this.page + 1);
    }
  }

  /**
   * Update the query string to record the pagination state. Use push state to
   * record the URL change in the browser history. Only push a new history
   * state if the URL has actually changed.
   */
  updateURL() {
    if (this.disableURL) {
      return;
    }

    let parsed = queryString.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    const hasChanged = parsed[PAGE_PARAM] != this.page || parsed[PER_PAGE_PARAM] != this.perPage;

    if (hasChanged) {
      parsed[PAGE_PARAM] = this.page;
      parsed[PER_PAGE_PARAM] = this.perPage;
      history.pushState({}, '', `?${queryString.stringify(parsed)}`);
    }
  }
}
