import { FILModal } from './modal';
import { FILComplianceModal, FILEditorialComplianceModal } from './compliance-modal';
import { FILWelcomeModal } from './welcome-modal';
import { FILLoginFromToolModal } from './login-modal';
import { detect_login_from_tools, bind_modal_triggers } from './utils';
import $ from 'jquery';

const DEFAULT_ZINDEX = 999;
const ZINDEX_STEP = 1;

export class FILModalFactory {
  static getNextZIndex() {
    if (!this.indexStarted) {
      this.revealOverlayZindex = 0;
      this.revealModalCounter = 1;
      this.modalIndexes = {};
      this.indexStarted = true;
    }

    if (this.revealOverlayZindex === 0) {
      this.revealOverlayZindex = DEFAULT_ZINDEX;
    }

    this.revealModalCounter += ZINDEX_STEP;
    return this.revealOverlayZindex + this.revealModalCounter;
  }

  static index(modal) {
    this.modalIndexes[modal.modalID] = modal;
  }

  static updateZIndex(modalID, zIndex) {
    if (!zIndex) {
      zIndex = this.getNextZIndex();
    }
    this.modalIndexes[modalID].updateZIndex(zIndex);
  }

  static create(rootDOM) {
    this.root = rootDOM;
    const modal_type = $(rootDOM).data('modal-type');
    const modal = this.getModalByType(modal_type);
    modal.updateZIndex(this.getNextZIndex());
    this.index(modal);
    return modal;
  }

  static getModalByType(type) {
    switch (type) {
      case 'compliance-modal':
        return new FILComplianceModal(this.root);
      case 'editorial-compliance-modal':
        return new FILEditorialComplianceModal(this.root);
      case 'welcome-modal':
        return new FILWelcomeModal(this.root);
      case 'login-from-tool':
        return new FILLoginFromToolModal(this.root);
      default:
        return new FILModal(this.root);
    }
  }
}

$(() => {
  detect_login_from_tools();
  bind_modal_triggers();

  $('.fil-modal.reveal-wrapper').each((_, modal) => {
    FILModalFactory.create(modal);
  });
});
